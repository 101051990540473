<template>
  <div class="UserDomainsRenew">
    <div v-if="userDomainsRenewError">
      <ErrorCard :mainMessage="messages.error.main" :optionalMessage="messages.error.email" />
    </div>
    <div v-else>
      <div v-if="isUserDomainsRenewLoading" class="user-domains-preview">
        <p class="o-default-h3 u-text-center">{{ $t('userDomains.domainList.loading') }}</p>
        <Loader />
      </div> <!-- /.user-domains-preview -->

      <div v-else class="o-container card-layout">
        <div v-if="domainsRenew.length === 0" class="user-domains-preview o-default-body">
          {{ $t('userDomains.domainList.noUserDomains') }}
        </div> <!-- /.user-domains-preview -->
        <md-table v-model="domainsRenew">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="labels.name" md-sort-by="name">
              {{ item.domain }}.{{ item.tld }}
            </md-table-cell>
            <md-table-cell :md-label="labels.domainStatus" md-sort-by="domainStatus" class="user-domain-status u-strong"
              v-bind:class="getDomainStatusLabelClass(item.domainStatus)">
              {{ displayUserDomainStatus(item.domainStatus) }}
            </md-table-cell>
            <md-table-cell :md-label="labels.domainPrice" md-sort-by="domainPrice">
              <span class="u-green u-strong">{{ renewDomainsPrice }}</span>
            </md-table-cell>
            <md-table-cell :md-label="labels.expireDate" md-sort-by="expireDate">
              {{ item.expireDate }}
            </md-table-cell>
            <md-table-cell>
              <md-button v-if=isReservedItemInCart(item) class="md-raised u-red-background btn-remove-from-cart"
                @click="deleteDomainCart(item.id);">
                {{ $t('common.actions.deleteDomainFromCart') }}
              </md-button>
              <md-button v-else class="md-raised md-primary btn-add-to-cart"
                @click="addToCart(item.id, item.domainStatus);">
                {{ $t('common.actions.addToCart') }}
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="o-container">
          <PaginationBlock :pages="pages" :currentPage.sync="currentPage" />
        </div>
      </div>
    </div>
    <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration"
      :md-active.sync="snackbar.showSnackbar" md-persistent>
      <span>{{ this.messages.addedToCart }}</span>
      <md-button class="md-primary" @click="snackbar.showSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
    <md-snackbar :md-position="snackbarDeletedFromCart.position" :md-duration="snackbarDeletedFromCart.duration"
      :md-active.sync="snackbarDeletedFromCart.showSnackbarDeletedFromCart" md-persistent>
      <span>{{ $t('minicart.delete') }}</span>
      <md-button class="md-primary" @click="snackbarDeletedFromCart.showSnackbarDeletedFromCart = false">{{
        $t('common.actions.close') }}</md-button>
    </md-snackbar>
    <md-dialog :md-active.sync="showAddToCartErrorDialog">
      <md-dialog-title>{{ $t('common.userDomains.title') }}</md-dialog-title>
      <md-dialog-content>
        <p class="u-font-body-regular o-dialog-content">{{ $t('userDomainsStatus.alreadyAdded') }}</p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="this.hideDomainAlreadyAddedDialog">{{ $t('common.actions.close')
          }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div> <!-- /.UserDomainsRenew -->
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_TO_CART,
  DELETE_ENTRY,
  FETCH_USER_DOMAINS
} from '@/store/actions.type';
import {
  SET_ADD_ERROR
} from '@/store/mutations.type';
import ErrorCard from '@/components/util/ErrorCard';
import Loader from '@/components/util/Loader';
import PaginationBlock from '@/components/util/PaginationBlock';

export default {
  name: 'UserDomainsRenew',
  components: {
    ErrorCard,
    Loader,
    PaginationBlock
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      currentPage: 1,
      domainNames: null,
      domainsRenew: [],
      isUserDomainsRenewLoading: false,
      renewDomainsPrice: '€7.00',
      showAddToCartErrorDialog: false,
      totalRenewDomains: 0,
      userDomainsRenewError: false,
      labels: {
        expireDate: this.$t('userDomains.labels.expireDate'),
        name: this.$t('userDomains.labels.name'),
        domainPrice: this.$t('common.price'),
        domainStatus: this.$t('userDomains.labels.domainStatus'),
      },
      domainStatusMap: {
        registered: 'registered',
        pendingDelete: 'pendingDelete'
      },
      temporary: {
        totalDomainsWithPrivateNameservers: 0
      },
      messages: {
        addedToCart: this.$t('userDomainsStatus.addedToCart'),
        error: {
          main: this.$t('userDomains.error.connectionError'),
          email: this.$t('contactEmailInreg'),
          addCart: '',
          alreadyAdded: this.$t('userDomainsStatus.alreadyAdded'),
        }
      },
      snackbar: {
        showSnackbar: false,
        duration: 2000,
        position: 'center',
      },
      snackbarDeletedFromCart: {
        showSnackbarDeletedFromCart: false,
        duration: 2000,
        position: 'center',
      },
    };
  },
  computed: {
    ...mapGetters([
      'addToCartError',
      'entries'
    ]),
    listConfig() {
      const filters = {
        domainStatus: 'PendingDelete,registered',
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage
      };
      return {
        filters
      };
    },
    pages() {
      if (this.isUserDomainsRenewLoading || this.totalRenewDomains <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.totalRenewDomains / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.filters.offset = (newValue - 1) * this.itemsPerPage;
      this.fetchUserDomains();
    },
  },
  mounted() {
    this.fetchUserDomains();
  },
  methods: {
    isReservedItemInCart(item) {
      if (this.entries) {
        return this.entries.some(cartItem => cartItem.id === item.id);
      }
    },
    deleteDomainCart(item) {
      let slug = "?entryId=" + item;
      this.$store.dispatch(DELETE_ENTRY, slug);
      this.showCopyConfirmationDeletedFromCart();
      this.fetchUserDomains();
    },
    hideDomainAlreadyAddedDialog() {
      this.showAddToCartErrorDialog = false;
    },
    setDomainIdInCart() {
      if (this.entries) {
        let domainIdInCart = this.entries.map((e) => e.id);
        return domainIdInCart.toString();
      }
    },
    setDomainNamesInCart() {
      if (this.entries) {
        this.domainNames = this.entries.map((e) => e.productName);
        return this.domainNames.toString();
      }
    },
    showCopyConfirmation() {
      this.snackbar.showSnackbar = true;
    },
    showCopyConfirmationDeletedFromCart() {
      this.snackbarDeletedFromCart.showSnackbarDeletedFromCart = true;
    },
    fetchUserDomains() {
      this.isUserDomainsRenewLoading = true;
      this.$store.dispatch(FETCH_USER_DOMAINS, this.listConfig)
        .then((data) => {
          this.domainNames = this.setDomainNamesInCart();
          this.userDomainsRenewError = false;
          this.domainsRenew = [];

          const registeredEntryMap = new Map();
          if (this.entries && this.entries.length > 0) {
            this.entries.forEach(entry => {
              registeredEntryMap.set(entry.productName, entry.id);
            });
          }

          for (let domain of data.userDomains) {
            if (domain.domainStatus === 'registered' || domain.domainStatus === 'pendingDelete') {
              if (domain.name) {
                if (registeredEntryMap.has(domain.name)) {
                  domain.id = registeredEntryMap.get(domain.name);
                }
              }
              this.domainsRenew.push(domain);
            }
          }
        })
        .catch(() => {
          this.userDomainsRenewError = true;
        })
        .finally(() => {
          this.isUserDomainsRenewLoading = false;
        });
    },
    addToCart(id, domainStatus) {
      domainStatus = 'RENEW';
      const params = [{
        id: id,
        period: 1,
        domainType: domainStatus
      }];
      this.$store.dispatch(ADD_TO_CART, params).then(() => {
        if (this.addToCartError === false) {
          this.showCopyConfirmation();
          this.fetchUserDomains()
        } else {
          this.messages.error.addCart = this.messages.error.alreadyAdded;
          this.showAddToCartErrorDialog = true;
          this.$store.commit(SET_ADD_ERROR, false);
        }
      });
    },
    getDomainStatusLabelClass(itemDomainStatus) {
      const successClass = 'u-green',
        errorClass = 'u-red',
        orangeClass = 'u-orange';

      switch (itemDomainStatus) {
        case this.domainStatusMap.registered:
          return successClass;
        case this.domainStatusMap.pendingDelete:
          return errorClass;
        default:
          return orangeClass;
      }
    },
    displayUserDomainStatus(itemDomainStatus) {
      switch (itemDomainStatus) {
        case this.domainStatusMap.registered:
          return this.$t('userDomains.domainStatus.registered');
        case this.domainStatusMap.pendingDelete:
          return this.$t('userDomains.domainStatus.pendingDelete');
        default:
          // eslint-disable-next-line
          return itemDomainStatus;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.UserDomainsRenew {
  width: 100%;
  background-color: $white;

  .card-layout {
    padding: 0;
  }

  .md-table-row {
    cursor: pointer;
  }

  .md-table-cell {
    text-align: center;
  }

  .btn-add-to-cart {
    max-width: 18rem;
    width: 100%;
  }

  .btn-remove-from-cart {
    max-width: 18rem;
    width: 100%;
    color: $white !important;
  }

  .user-domains-preview {
    text-align: center;
  }
}
</style>